import React, { useState, useEffect } from 'react';
import { columnManager } from '../Common/ColumnManager';
import { GetCompReportPitcherData, GetCompReportBatterData, GetCompReportData } from '../../Services/ApiSvc';
import TabulatorTable from '../Common/TabulatorTable';
import ExcelExporter from '../Common/ExcelExporter';

function PlayerStats(props) {
    const [data, setData] = useState(null)
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setData(null);
        if (props.player.id > 0) {
            let playerSeasons = [
                {
                    playerId: props.player.id,
                    platformYear: props.player.maxSeason,
                    posFlag: props.player.posFlag,
                    primaryPos: props.player.primaryPos
                }
            ];
            if (props.player.id === 19755) // Ohtani
                playerSeasons[0].posFlag = 'B';

            let columnGroups = [];
            if (playerSeasons[0].posFlag === 'B') {
                columnGroups = [1, 5];
            }
            else if (playerSeasons[0].primaryPos === 15){
                columnGroups = [4, 6];
            }
            else {
                columnGroups = [3, 6];
            }

            (async function GetData() {
                let colGroups = await Promise.all(columnGroups.map(colGroupId => columnManager.getColumnsByGroup(colGroupId)))
                let uniqueStatIds = new Set();
                let uniqueStats = [];
                colGroups.forEach(colGroup => {
                    colGroup.forEach(col => {
                        if (col.columns?.length > 0) {
                            col.columns.forEach(subCol => {
                                if (!uniqueStatIds.has(subCol.id)) {
                                    uniqueStats.push(subCol);
                                    uniqueStatIds.add(subCol.id);
                                }
                            })
                        } else {
                            if (!uniqueStatIds.has(col.id)) {
                                uniqueStats.push(col);
                                uniqueStatIds.add(col.id);
                            }
                        }
                    })
                });

                let compReportRes = await GetCompReportData(playerSeasons, uniqueStats, 0);
                if (compReportRes.playersData?.length > 0) {
                    let resColumnMap = {};
                    compReportRes.columns.forEach(resColumn => {
                        resColumnMap[resColumn.id] = resColumn;
                    });
                    let resColGroups = colGroups.map(colGroup => {
                        return colGroup.map(col => {
                            let retCol = Object.assign({}, col);
                            if (col.columns?.length > 0) {
                                retCol.columns = col.columns.map(subCol => {
                                    return resColumnMap[subCol.id];
                                });
                            } else {
                                retCol = resColumnMap[col.id];
                            }
                            return retCol;
                        })
                    });
                    setColumns(resColGroups);
                    setData(compReportRes.playersData[0])
                }
            })();
        }
    }, [props.player])

    return (
        <div className='player-stats'>
            {
                columns && data?.length > 0 &&
                <>
                    <ExcelExporter
                        columns={{
                            standard: columns[0],
                            statcast: columns[1],
                        }}
                        data={{
                            standard: data,
                            statcast: data,
                        }}
                        notes={[]}
                        reportName={'PlayerStats'}
                        sheetNames={['standard', 'statcast']}
                    />
                    <span style={{ backgroundColor: '#FED8B1' }}>Click + sign on 2020 and Career rows to show 2020 statistics on a 162 game basis</span>
                    {columns.map(colGroup => {
                        return <div style={{
                            width: data
                                ? (colGroup.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                                : '100%'
                        }}>
                            <TabulatorTable
                                cols={colGroup}
                                data={data}
                                header={data[0].Player}
                                initSort={[{ column: 'Season', dir: 'asc' }]}
                                dataTreeElementColumn='Team'
                            />
                        </div>
                    }) }
                </>
            }
            <br />
        </div>
    );
}

export default PlayerStats;